import React, { useEffect, useMemo, useState } from 'react'
import { fieldsList } from './FormField'
import FormContent from '../form/Form'
import { useStoreViewContext } from './../../context/storeViewContext/StoreViewContext'
import apiCollection from '../../api'
import { EnumConfigType, EnumOperateType } from '../../types/enums'
import { IAreaItem } from '../../api/apiModel/publicApi'
import { IWholesaleItem } from '../../api/apiModel/wholesale'
import { IFromMapItem } from '../../types/interface'
import { message, Spin } from 'antd'
import { FormChangeInfo } from 'rc-field-form/lib/FormContext'

enum EnumFormName {
  StoreInfo = 'Store Info(Wholesale)',
}

interface WholesaleDetailProps {
  isEdit?: boolean
  editData?: IWholesaleItem
  operateCallback: (type: EnumOperateType) => void
}

interface IAreaOptions extends IAreaItem {
  label: string
  value: number
  children?: IAreaOptions[]
}

const WholesaleDetailContent = ({
  isEdit,
  editData,
  operateCallback,
}: WholesaleDetailProps) => {
  const { configMap, areaList, employeeList } = useStoreViewContext()

  const [initData, setInitData] = useState(null)
  const [isLoading, displayLoading] = useState<boolean>(false)

  const optionsMap = useMemo(() => {
    let areaOptions: IAreaOptions[] = []
    if (configMap.size && !!areaList?.length) {
      const areaLevelTypeList = configMap.get(EnumConfigType.Area_Level)
      areaOptions = areaList?.reduce(
        (preList, item) =>
          item.Level ==
          areaLevelTypeList.find((tItem) => tItem.Value == '省')?.Id
            ? preList.concat({ ...item, label: item.Name_CN, value: item.Id })
            : preList,
        []
      )
      areaOptions?.map((item) => {
        item.children = areaList?.reduce(
          (preList, cItem) =>
            cItem.Parent_Id == item.Id
              ? preList.concat({
                  ...cItem,
                  label: cItem.Name_CN,
                  value: cItem.Id,
                })
              : preList,
          []
        )
      })
    }
    return {
      employeeType: configMap.get(EnumConfigType.Position),
      employeeList,
      regionList: configMap.get(EnumConfigType.Store_Region),
      storeType: configMap.get(EnumConfigType.Wholesale_Type),
      storeStatus: configMap.get(EnumConfigType.Wholesale_Status),
      storeLevel: configMap.get(EnumConfigType.Wholesale_Level),
      areaOptions,
    }
  }, [employeeList, configMap, areaList])

  const formMap = useMemo((): IFromMapItem[] => {
    const fields =
      fieldsList?.map((item) =>
        ['Store_Code', 'No'].includes(item.name)
          ? { ...item, extra: { ...item.extra, disabled: isEdit } }
          : item
      ) ?? []
    return [
      {
        panelName: 'Store Info(Wholesale)',
        fieldsList: fields,
        showSubmitButton: true,
        showCancelButton: true,
      },
    ]
  }, [fieldsList, isEdit])

  const initFormatData = () => {
    if (!editData || !Object.keys(editData).length) return

    const {
      Authorization_Start_Date,
      Authorization_End_Date,
      Opening_Date,
      Closing_Date,
      Province,
      City,
      ...defaultWholesaleData
    } = editData

    if (Authorization_Start_Date) {
      defaultWholesaleData['Authorization_Date'] = [
        Number(Authorization_Start_Date) * 1000,
        Authorization_End_Date * 1000,
      ]
    }

    setInitData({
      ...defaultWholesaleData,
      Opening_Date: Opening_Date ? Opening_Date * 1000 : null,
      Closing_Date: Closing_Date ? Closing_Date * 1000 : null,
      ProvinceCity: [Province, City],
    })
  }

  const onFormChange = async (name: string, info: FormChangeInfo) => {
    const { changedFields } = info
    if (
      name === EnumFormName.StoreInfo &&
      changedFields?.[0]?.name?.[0] === 'Address'
    ) {
      displayLoading(true)
      const form = info.forms[EnumFormName.StoreInfo]
      form.setFields([
        { name: 'Longitude', value: '' },
        { name: 'Latitude', value: '' },
      ])

      const { value: Address } = changedFields[0]

      const formatPathname =
        (typeof window !== 'undefined' &&
          window.location.pathname.replace(/(\/)?/gi, '').toUpperCase()) ||
        'WHOLESALE'

      const keyName = 'default'
      const formationFormName = `${formatPathname}_${keyName
        .replace(/\s+/gi, '_')
        .toUpperCase()}`
      const dataStr = window.localStorage.getItem(formationFormName)
      const catchData = dataStr ? JSON.parse(dataStr) : {}

      try {
        if (!Address) {
          delete catchData.Address
          throw new Error('Address is null!')
        }

        const result = await apiCollection.getGeocoding({ Address })

        if (!result.success) {
          throw new Error(result.message)
        }

        const { lat, lng } = result.data
        const Longitude = lng.toFixed(6)
        const Latitude = lat.toFixed(6)
        form.setFields([
          { name: 'Longitude', value: Longitude },
          { name: 'Latitude', value: Latitude },
        ])
        form.validateFields(['Longitude', 'Latitude'])

        window.localStorage.setItem(
          formationFormName,
          JSON.stringify({ ...catchData, ...{ Longitude, Latitude } })
        )
      } catch (error) {
        message.error('未查询到对应经纬度，请检查地址输入')
        delete catchData.Longitude
        delete catchData.Latitude

        window.localStorage.setItem(
          formationFormName,
          JSON.stringify(catchData)
        )
      }
      displayLoading(false)
    }
  }

  const submitCallback = async (formData): Promise<void> => {
    const { Authorization_Date, ProvinceCity, ...defaultWholesaleData } =
      formData
    const params: IWholesaleItem = {
      ...defaultWholesaleData,
      Authorization_Start_Date: formData?.Authorization_Date?.[0],
      Authorization_End_Date: formData?.Authorization_Date?.[1],
      Province: ProvinceCity?.[0] ?? null,
      City: ProvinceCity?.[1] ?? null,
    }
    const result = isEdit
      ? await apiCollection.updateWholesale(params)
      : await apiCollection.addWholesale(params)
    if (result.success) {
      operateCallback(EnumOperateType.Submit)
    } else {
      message.error(result.message)
      throw Error(result.message)
    }
  }

  const cancelCallback = () => {
    operateCallback(EnumOperateType.Cancel)
  }

  useEffect(() => {
    initFormatData()
  }, [editData])

  return (
    <>
      <Spin
        spinning={isLoading}
        tip={'正在匹配经纬度信息，请稍候...'}
        delay={500}
      >
        <FormContent
          formMap={formMap}
          optionsMap={optionsMap}
          isEdit={isEdit}
          initData={initData}
          formKeyName={initData?.No}
          submitCallback={submitCallback}
          cancelCallback={cancelCallback}
          onFormChange={onFormChange}
        />
      </Spin>
    </>
  )
}

export default WholesaleDetailContent
