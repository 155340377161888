import React, { useEffect, useState } from 'react'
import WholesaleDetailContent from './Detail'
import WholesaleListContent from './List'
import apiCollection from '../../api'
import { IWholesaleItem } from '../../api/apiModel/wholesale'
import { EnumMenuTypes, EnumOperateType } from '../../types/enums'
import { message } from 'antd'
import { IPageInfo } from '../../types/interface'
import Menu from '../tools/Menu'

export interface IOperateCallbackParams {
  item?: IWholesaleItem
  filter?: object
  pageInfo?: IPageInfo
}

const WholesaleContent = () => {
  const [isShowDetail, displayDetailPage] = useState<boolean>(false)
  const [whoelsaleList, setWhoelsaleList] = useState<IWholesaleItem[]>([])
  const [editData, setEditData] = useState<IWholesaleItem>()
  const [filterData, setFilterData] = useState<object>({})
  const [pageInfo, setPageInfo] = useState<IPageInfo>({ page: 1, size: 10 })
  const [isLoading, showLoading] = useState<boolean>(false)
  const [wholesaleTotal, setWholesaleTotal] = useState<number>(0)
  const [storeCodeList, setStoreCodeList] = useState<{ value: string }[]>([])

  const getWholesaleList = async () => {
    showLoading(true)
    const result = await apiCollection.getWholesaleList({
      ...filterData,
      ...pageInfo,
    })

    if (result.success) {
      const { data: list, total } = result.data
      setWhoelsaleList(list)
      setWholesaleTotal(total)
    } else {
      message.error(result.message)
      setWhoelsaleList([])
      setWholesaleTotal(0)
    }

    showLoading(false)
  }

  const getAllStoreList = async () => {
    const stores = await apiCollection.getWholesaleList({})
    const storeList =
      stores.data?.data?.map((item) => ({ value: item?.Store_Code })) ?? []
    setStoreCodeList(storeList)
  }

  const operateCallback = (
    type: EnumOperateType,
    data?: IOperateCallbackParams
  ) => {
    switch (type) {
      case EnumOperateType.Update:
        displayDetailPage(true)
        setEditData(data.item)
        break

      case EnumOperateType.Add:
        displayDetailPage(true)
        break

      case EnumOperateType.Cancel:
        setEditData(null)
        displayDetailPage(false)
        break

      case EnumOperateType.Submit:
        setEditData(null)
        displayDetailPage(false)
        getWholesaleList()
        break

      case EnumOperateType.Filter:
        setFilterData(data.filter)
        setPageInfo((current) => ({ ...current, page: 1 }))
        break

      case EnumOperateType.ChangePage:
        setPageInfo((current) => ({ ...current, ...data.pageInfo }))
        break
    }
  }

  useEffect(() => {
    getWholesaleList()
  }, [pageInfo])

  useEffect(() => {
    getAllStoreList()
  }, [])

  return (
    <>
      <Menu
        current={EnumMenuTypes.WholesaleList}
        refreshPage={() => {
          operateCallback(EnumOperateType.Cancel)
        }}
      />
      {isShowDetail && (
        <WholesaleDetailContent
          isEdit={!!editData?.No}
          editData={editData}
          operateCallback={operateCallback}
        />
      )}
      {!isShowDetail && (
        <WholesaleListContent
          list={whoelsaleList}
          total={wholesaleTotal}
          filterData={filterData}
          isLoading={isLoading}
          pageInfo={pageInfo}
          storeCodeList={storeCodeList}
          operateCallback={operateCallback}
        />
      )}
    </>
  )
}

export default WholesaleContent
