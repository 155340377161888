import React, { useMemo } from 'react'
import { TablePaginationConfig } from 'antd'
import { useStoreViewContext } from './../../context/storeViewContext/StoreViewContext'
import { EnumConfigType } from '../../types/enums'
import { IWholesaleItem } from '../../api/apiModel/wholesale'
import { IPageInfo } from '../../types/interface'
import Table from '../tools/Table'
import moment from 'moment'

interface IWholesaleTableProps {
  list: IWholesaleItem[]
  total: number
  pageInfo: IPageInfo
  onPageChange: (props: { size: number; page: number }) => void
  onClickByNo: (data: IWholesaleItem) => void
}

const WholesaleTable = ({
  list,
  total,
  pageInfo,
  onPageChange,
  onClickByNo,
  ...props
}: IWholesaleTableProps) => {
  const { configMap } = useStoreViewContext()

  const columns = [
    {
      title: 'No.',
      dataIndex: 'No',
      width: 80,
      fixed: true,
      render: (text, item) => (
        <a
          onClick={(e) => {
            onClickByNo(item)
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Landlord',
      dataIndex: 'Landlord',
      fixed: true,
    },
    {
      title: 'CN Code',
      dataIndex: 'Store_Code',
    },
    {
      title: 'Agent Name',
      dataIndex: 'Agent',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      render: (id) => {
        return (
          <span>
            {
              configMap
                .get(EnumConfigType.Wholesale_Status)
                ?.find((item) => item.Id == id)?.Value
            }
          </span>
        )
      },
    },
    {
      title: 'Opening Date',
      dataIndex: 'Opening_Date',
      render: (time) => (
        <span>{time ? moment(time * 1000).format('YYYY-MM-DD') : ''}</span>
      ),
    },
    {
      title: 'Store Size',
      dataIndex: 'Size',
      render: (size) => <span>{size ? `${size}m²` : ''}</span>,
    },
    {
      title: 'Address',
      dataIndex: 'Address',
    },
    {
      title: 'Auth Time Range',
      dataIndex: 'Authorization_Date',
    },
  ]

  const data = useMemo(() => {
    return list.map((item) => {
      const dataItem = {
        ...item,
        key: item.No,
      }

      if (item.Authorization_Start_Date) {
        dataItem['Authorization_Date'] =
          moment(item.Authorization_Start_Date * 1000).format('YYYY/MM/DD') +
          ' - ' +
          moment(item.Authorization_End_Date * 1000).format('YYYY/MM/DD')
      }

      return dataItem
    })
  }, [list])

  const onChangeByTable = (pagination: TablePaginationConfig) => {
    const { pageSize: size, current: page } = pagination
    if (pageInfo.size != size || pageInfo.page != page) {
      onPageChange({ size, page })
    }
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        onChange={onChangeByTable}
        scroll={{ x: 1200 }}
        pagination={{
          total,
          showTotal:(total) => `Total ${total} items`,
          current: pageInfo.page,
          pageSize: pageInfo.size,
          showSizeChanger: true,
        }}
      />
    </>
  )
}

export default WholesaleTable
