import { EnumFormFieldType, EnumRolePostion } from '../../types/enums'
import { IFormField } from '../../types/interface'

export const fieldsList: IFormField[] = [
  {
    name: 'Region',
    label: 'Region',
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'regionList',
    col: 8,
    placeholder: '',
    onChange: ({ value, form, optionsMap }) => {
      const RMTypeId =
        optionsMap.employeeType?.find(
          (item) => item.Value == EnumRolePostion.RM
        )?.Id ?? 0
      const RMId =
        optionsMap.employeeList?.find(
          (item) => item.Position == RMTypeId && item.Region == value
        )?.Employee_No ?? null
      form.setFields([{ name: 'RM', value: RMId }])
    },
  },
  {
    name: 'RM',
    label: EnumRolePostion.RM,
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'employeeList',
    extra: {
      disabled: true,
    },
    col: 8,
  },
  {
    name: 'No',
    label: 'No.',
    require: true,
    type: EnumFormFieldType.Input,
    col: 8,
    rules: [
      {
        pattern: /^[0-9a-zA-Z]+$/,
        message: '只能输入数字或字母',
      },
    ],
    getValueFromEvent: (event, form) => {
      return event.target.value.replace(/[^0-9a-zA-Z]+/g, '')
    },
  },
  {
    name: 'Store_Code',
    label: 'CN Code',
    require: true,
    type: EnumFormFieldType.Input,
    col: 8,
    rules: [
      {
        pattern: /^[0-9a-zA-Z]+$/,
        message: '只能输入数字或字母',
      },
    ],
    getValueFromEvent: (event, form) => {
      return event.target.value.replace(/[^0-9a-zA-Z]+/g, '')
    },
  },
  {
    name: 'Status',
    label: '状态',
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'storeStatus',
    col: 8,
  },
  {
    name: 'Store_Type',
    label: '店铺类型',
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'storeType',
    col: 8,
  },
  {
    name: 'Size',
    label: '店铺面积',
    require: true,
    type: EnumFormFieldType.Input,
    col: 8,
    rules: [
      {
        pattern: new RegExp(/^(([0-9]+)(([.][0-9]*)?))$/),
        message: `请输入正确的面积`,
        validator(_, value) {
          if (!value) return Promise.resolve('')
          if (value > 0) {
            return Promise.resolve()
          }
          return Promise.reject(new Error('请输入正确的面积'))
        },
      },
    ],
  },
  {
    name: 'Opening_Date',
    label: '开店时间',
    require: true,
    type: EnumFormFieldType.DatePicker,
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value) return Promise.resolve('')
          const closingDate = getFieldValue('Closing_Date')
          if (
            closingDate &&
            closingDate?.unix() <= value?.unix() &&
            closingDate?.unix() - value?.unix() < 3600
          ) {
            return Promise.reject(new Error('开店时间不能晚于关店时间。'))
          }
          return Promise.resolve()
        },
      }),
    ],
    col: 8,
    onChange: ({ form }) => {
      if (form.getFieldValue('Closing_Date')) {
        form.validateFields(['Closing_Date'])
      }
    },
  },
  {
    name: 'Closing_Date',
    label: '关店时间',
    type: EnumFormFieldType.DatePicker,
    col: 8,
    onChange: ({ form }) => {
      if (form.getFieldValue('Opening_Date')) {
        form.validateFields(['Opening_Date'])
      }
    },
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value) return Promise.resolve('')
          const openingDate = getFieldValue('Opening_Date')
          if (
            openingDate &&
            openingDate?.unix() < value?.unix() &&
            value?.unix() - openingDate?.unix() > 3600
          ) {
            return Promise.resolve()
          }
          return Promise.reject(
            new Error('请先选择开店时间，并且关店时间晚于开店时间。')
          )
        },
      }),
    ],
  },
  {
    name: 'Landlord',
    label: 'Landlord',
    require: true,
    type: EnumFormFieldType.Input,
    col: 12,
  },
  {
    name: 'Store_Level',
    label: '店铺类别',
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'storeLevel',
    col: 12,
  },
  {
    name: 'Business_Hours',
    label: '营业时间',
    require: true,
    type: EnumFormFieldType.Input,
  },
  {
    name: 'ProvinceCity',
    label: '省/直辖市',
    require: true,
    type: EnumFormFieldType.Cascader,
    optionsName: 'areaOptions',
    extra: {
      showSearch: {
        filter: (inputValue, path) =>
          path.some(
            (option) =>
              option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
          ),
      },
    },
  },
  {
    name: 'Address',
    label: '授权地址',
    type: EnumFormFieldType.Input,
    require: true,
  },
  {
    name: 'Authorization_Code',
    label: '授权编码',
    require: true,
    type: EnumFormFieldType.Input,
    col: 12,
  },
  {
    name: 'Authorization_Date',
    label: '授权时间',
    require: true,
    type: EnumFormFieldType.DateRangePicker,
    col: 12,
  },
  {
    name: 'Contact_Person',
    label: '店铺联系人',
    type: EnumFormFieldType.Input,
    col: 12,
  },
  {
    name: 'Contact_Number',
    label: '电话',
    type: EnumFormFieldType.Input,
    col: 12,
  },
  {
    name: 'Agent',
    label: '代理商',
    type: EnumFormFieldType.Input,
  },
  {
    name: 'Longitude',
    label: '经度',
    require: true,
    type: EnumFormFieldType.Input,
    col: 12,
    extra: {
      disabled: true,
    },
  },
  {
    name: 'Latitude',
    label: '纬度',
    require: true,
    type: EnumFormFieldType.Input,
    col: 12,
    extra: {
      disabled: true,
    },
  },
]

export const filterFields: IFormField[] = [
  {
    name: 'No',
    label: 'No.',
    type: EnumFormFieldType.Input,
    col: 12,
    rules: [
      {
        pattern: /^[0-9a-zA-Z]+$/,
        message: '只能输入数字或字母',
      },
    ],
    getValueFromEvent: (event, form) => {
      return event.target.value.replace(/[^0-9a-zA-Z]+/g, '')
    },
  },
  {
    name: 'Store_Code',
    label: 'CN Code',
    type: EnumFormFieldType.AutoComplete,
    col: 12,
    optionsName: 'storeCodeList',
    rules: [
      {
        pattern: /^[0-9a-zA-Z]+$/,
        message: '只能输入数字或字母',
      },
    ],
  },
  {
    name: 'Agent',
    label: 'Agent Name',
    type: EnumFormFieldType.Input,
    col: 12,
  },
  {
    name: 'Landlord',
    label: 'Landlord',
    type: EnumFormFieldType.Input,
    col: 12,
  },
  {
    name: 'Status',
    label: '状态',
    type: EnumFormFieldType.Select,
    col: 8,
    defaultValue: 'Running',
    optionsName: 'storeStatus',
  },
  {
    name: 'Address',
    label: 'Address',
    type: EnumFormFieldType.Input,
    col: 16,
  },
]
