import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { filterFields } from './FormField'
import FormContent from '../form/Form'
import { useStoreViewContext } from './../../context/storeViewContext/StoreViewContext'
import { EnumConfigType, EnumExcelType } from '../../types/enums'
import { IFromMapItem } from '../../types/interface'
import Download from './../icon/Download'
import IconContent from './../icon/IconContent'
import { PlusOutlined } from '@ant-design/icons'
import UploadByExcel, { IUploadTemplateOptions } from '../upload/Upload'

const WholesaleFilterContainer = styled.div`
  padding-bottom: 4.8rem;
  position: relative;
`

interface IWholesaleFilterProps {
  filterData: object
  storeCodeList: { value: string; label?: string }[]
  changeFilter: (filterData: object) => void
  addWholesale: () => void
}

const WholesaleFilterContent = ({
  filterData,
  storeCodeList,
  changeFilter,
  addWholesale,
}: IWholesaleFilterProps) => {
  const { configMap } = useStoreViewContext()

  const optionsMap = useMemo(() => {
    return {
      regionList: configMap.get(EnumConfigType.Store_Region),
      storeStatus: configMap.get(EnumConfigType.Wholesale_Status),
      storeCodeList,
    }
  }, [configMap, storeCodeList])

  const templateOptions: IUploadTemplateOptions[] = [
    {
      label: '代理商模板',
      fileName: EnumExcelType.UploadWholesale,
      templateUrl: `report/${EnumExcelType.UploadWholesale}`,
      uploadUrl: `report/${EnumExcelType.UploadWholesale}`,
    },
  ]

  const formMap: IFromMapItem[] = [
    {
      panelName: 'Store Info(Wholesale) Filter',
      fieldsList: filterFields,
      showSubmitButton: true,
      submitButtonLabel: '搜索',
      cancelButtonLabel: '重置',
      showCancelButton: true,
      buttonPosition: 'right',
      customFormButton: [
        <Download fileUrl={`report/${EnumExcelType.StoreReport}`} />,
        <IconContent
          icon={PlusOutlined}
          tips="新增代理"
          clickCallback={addWholesale}
        />,
        <UploadByExcel
          templateOptions={templateOptions}
          uploadCallback={() => {
            changeFilter(filterData)
          }}
        />,
      ],
    },
  ]

  const resetFilter = () => {
    changeFilter({})
  }

  return (
    <WholesaleFilterContainer>
      <FormContent
        isEdit={true}
        initData={filterData}
        hiddenPanel={true}
        disableCatch={true}
        formMap={formMap}
        optionsMap={optionsMap}
        expandIcon={() => ''}
        submitCallback={changeFilter}
        cancelCallback={resetFilter}
      />
    </WholesaleFilterContainer>
  )
}

export default WholesaleFilterContent
