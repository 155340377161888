import React from 'react'

import { AuthorizationProvider } from '../context/authorizationContext/AuthorizationContext'
import { StoreViewProvider } from '../context/storeViewContext/StoreViewContext'
import Header from '../component/tools/Header'
import BodyContainer from '../component/layout/BodyContainer'
import Wholesale from '../component/wholesale/Wholesale'

import { EnumMenuTypes } from '../types/enums'

const WholesaleListPageContainer = () => {
  return (
    <AuthorizationProvider>
      <StoreViewProvider>
        <Header title={EnumMenuTypes.WholesaleList} />
        <BodyContainer>
          <Wholesale />
        </BodyContainer>
      </StoreViewProvider>
    </AuthorizationProvider>
  )
}

export default WholesaleListPageContainer
