import React from 'react'
import { IWholesaleItem } from '../../api/apiModel/wholesale'
import WholesaleTable from './Table'
import WholesaleFilter from './Filter'
import { EnumConfigType, EnumOperateType } from '../../types/enums'
import { IOperateCallbackParams } from './Wholesale'
import { Spin } from 'antd'
import { IPageInfo } from '../../types/interface'
import { useStoreViewContext } from './../../context/storeViewContext/StoreViewContext'

interface IWholesaleListProps {
  list: IWholesaleItem[]
  isLoading: boolean
  total: number
  pageInfo: IPageInfo
  filterData: object
  storeCodeList: { value: string; label?: string }[]
  operateCallback: (
    type: EnumOperateType,
    date?: IOperateCallbackParams
  ) => void
}

const WholesaleListContent = ({
  list,
  pageInfo,
  total,
  isLoading,
  filterData,
  storeCodeList,
  operateCallback,
}: IWholesaleListProps) => {
  const { configMap } = useStoreViewContext()

  const changeFilter = (data: object) => {
    const filter = {}

    for (const [key, value] of Object.entries(data)) {
      if (value) filter[key] = value

      if (key === 'Status' && value === undefined)
        filter[key] =
          configMap
            .get(EnumConfigType.Wholesale_Status)
            .find((item) => item.Value === 'Running')?.Id ?? 0
    }
    operateCallback(EnumOperateType.Filter, { filter })
  }

  return (
    <>
      <WholesaleFilter
        filterData={filterData}
        storeCodeList={storeCodeList}
        changeFilter={changeFilter}
        addWholesale={() => {
          operateCallback(EnumOperateType.Add)
        }}
      />
      <Spin spinning={isLoading} delay={300}>
        <WholesaleTable
          list={list}
          total={total}
          pageInfo={pageInfo}
          onPageChange={(pageInfo) =>
            operateCallback(EnumOperateType.ChangePage, { pageInfo })
          }
          onClickByNo={(item) => {
            operateCallback(EnumOperateType.Update, { item })
          }}
        />
      </Spin>
    </>
  )
}

export default WholesaleListContent
